import * as React from 'react'
import { Helmet } from 'react-helmet'
import { GlobalStyle, Logo, ScheduleLink, SuccessMessage } from '../styles'
import { Link } from 'gatsby'
import LogoSrc from '../images/logo.png'

const Success = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Success</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <SuccessMessage>
        <div>
          <Logo src={LogoSrc} alt="logo" />
          Thank you for applying! Next step is to schedule an appointment.
          <Link to="/schedule-interview">
            <ScheduleLink>Schedule Interview</ScheduleLink>
          </Link>
        </div>
      </SuccessMessage>
    </React.Fragment>
  )
}

export default Success
